import {mapGetters} from 'vuex';

export default {
  name: 'page-list-mixins',
  provide() {
    return {
      pageListThis: this,
    }
  },
  computed: {
    ...mapGetters(['userInfo', 'token', 'baseData', 'height']),
    detailID() {
      return this.tableSelectItem.length === 1 ? this.tableSelectItem[0].id : null;
    },
    resultHeight() {
      if (this.tableHeight) return this.tableHeight;
      if (this.$router.currentRoute.params.id) {
        if (this.mid) {
          return this.height - 390;
        } else {
          return this.height - 300;
        }
      } else {
        if (this.mid) {
          return this.height - 300;
        } else {
          return null
        }
      }
    }
  },
  props: {
    midData: {type: Array, default: () => []},
    modData: {type: Array, default: () => []},
    mid: {type: [Number, String], default: null},
    tableHeight: {type: [Number, String], default: null},
  },
  watch: {
    'detailID': function (data) {
      if (!data && this.detailFlag) {
        this.detailFlag = false;
        return false;
      }
      this.drawerLoading = true;
      window.setTimeout(() => {
        this.drawerLoading = false;
      },)
    },
  },
  data() {
    return {
      tableList: [],
      oldId: null,
      tableSelectItem: [],
      modShow: false,
      tabName: '1',
      detailFlag: false,
      loading: false,
      drawerLoading: false,
      params: {},
    }
  },
  methods: {
    getList(nextType) {
      this.oldId = null
      this.tableSelectItem = [];
      this.$refs['table'].selectAll(false);
      this.$refs['table'].getList(nextType);
    },
    add() {
      this.modShow = true;
      this.tableSelectItem = [];
      this.$refs['table'].selectAll(false);
    },
    checkItem(selection) {
      this.tableSelectItem = selection;
    },
    delItem(url, params = {id: this.tableSelectItem[0].id}, method = '$del') {
      this.$Modal.confirm({
        title: '提示',
        content: '确认要删除这 ' + this.tableSelectItem.length + ' 条记录么?一旦删除将无法恢复!',
        okText: '删除',
        cancelText: '取消',
        onOk: () => {
          this[method](url, params).then(res => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '提示',
                desc: '操作成功！'
              });
              this.getList();
            } else {
              if (res.code === 408) {
                window.setTimeout(() => {
                  this.confirmDel(res, url, params, method);
                }, 300)
              }
            }
          })
        }
      })
    },
    confirmDel(data, url, params, method = '$del') {
      this.$Modal.confirm({
        title: '强制删除提示',
        content: data.msg + '<br>确认要强制删除这条记录么？强制删除可能会出现数据错误，请再次确认此操作！',
        okText: '强制删除',
        cancelText: '取消',
        onOk: () => {
          params.isAdmin = 1
          this[method](url, params).then(res => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '提示',
                desc: '操作成功！'
              });
              this.getList();
            }
          })
        }
      })
    }
  }
}
