<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/comment/ListDetail"
        :params="params"
        keyID="0393390B67BD63BCCEB27C883DB7C03B"
        :multiBtn="multiBtn"
        showContextMenu
        @on-selection-change="checkItem"
        :headerColumns="headerColumns"
        :searchFun="searchFun"
        >
      <template slot="search">
        <FormItem label="项目所在地区" prop="region" show>
          <ys-region changeOnSelect @on-change="regionChange" v-model="params.region" />
        </FormItem>
        <FormItem label="打卡时间" prop="daterange">
          <ys-date-picker type="datetimerange" v-model="params.daterange" clearable style="width: 340px" placeholder="时间周期"></ys-date-picker>
        </FormItem>
        <FormItem label="服务机构" prop="serviceName">
          <Input placeholder="服务机构" clearable readonly style="width: 250px;" v-model="params.serviceName" search enter-button="服务机构" @on-search="serviceFlag = true"></Input>
        </FormItem>
        <FormItem label="保险机构" prop="insOrgName">
          <Input placeholder="保险机构" clearable readonly style="width: 250px;" v-model="params.insOrgName" search enter-button="保险机构" @on-search="insOrgFlag = true"></Input>
        </FormItem>
        <FormItem label="风险等级" prop="riskGrade" show>
          <Select v-model="params.riskGrade" clearable placeholder="隐患等级" style="width: 120px">
            <Option v-for="(item,index) in baseData['风险等级']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="隐患状态" prop="status" show>
          <Select v-model="params.status" clearable placeholder="隐患状态" style="width: 120px">
            <Option v-for="(item,index) in baseData['隐患状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="关键词" prop="keyword" show>
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="detail"></template>
    </ys-page-list>
    <ys-modal v-model="serviceFlag" :width="1200" title="选择服务机构">
      <OrgComponent :limit="1" :oTypeListValue="[3, 1]"  @on-ok="getOrgData"></OrgComponent>
    </ys-modal>
    <ys-modal v-model="insOrgFlag" :width="1200" title="选择保险机构">
      <OrgComponent :limit="1" :oType="1" @on-ok="getOrgData"></OrgComponent>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import OrgComponent from "_c/org";

export default {
  name: 'projects',
  mixins: [pageList],
  components: {
    OrgComponent
  },
  data() {
    return {
      params: {
        orgId: '',
        region: '',
        provinceCode: '',
        cityCode: '',
        countyCode: '',
        daterange: [],
        signStartAt: '',
        signEndAt: '',
        riskGrade: '',
        status: '',
        serviceName: '',
        insOrgName: '',
        serveOrgId: '',
        insOrgId: '',
        keyword: '',
      },
      serviceFlag: false,
      insOrgFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 50, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {minWidth: 220, title: '保单号', key: 'number', align: 'center'},
        {minWidth: 150, title: '起保日期', key: 'startAt', align: 'center', renderConfig: {type: 'formatDate'}},
        {minWidth: 150, title: '终保日期', key: 'endAt', align: 'center', renderConfig: {type: 'formatDate'}},
        {minWidth: 150, title: '施工工期起期', key: 'startReal', align: 'center', renderConfig: {type: 'formatDate'}},
        {minWidth: 150, title: '施工工期止期', key: 'endReal', align: 'center', renderConfig: {type: 'formatDate'}},
        {width: 150, title: '服务机构', key: 'serveOrgName', align: 'center'},
        {width: 150, title: '保险公司', key: 'insOrgName', align: 'center'},
        {width: 180, title: '项目所在区县', key: 'provinceCode', align: 'center', renderConfig: {type: 'area', key: ['countyCode', 'cityCode', 'provinceCode']}},
        {width: 180, title: '施工许可证', key: 'licenseNum', align: 'center'},
        {width: 200, title: '合同金额(万元)', key: 'contractPrice', align: 'center'},
        {width: 150, title: '施工单位', key: 'enterpriseName', align: 'center'},
        {width: 200, title: '打卡日期', key: 'signTime', align: 'center', renderConfig: {type: 'formatDate', fmt: 'yyyy-MM-dd hh:mm:ss'}},
        {minWidth: 400, title: '隐患问题', key: 'remarksText', align: 'center', tooltip:true},
        {width: 180, title: '风险类别', key: 'categoryName', align: 'center'},
        {width: 180, title: '风险等级', key: 'riskGrade', align: 'center', renderConfig: {type: 'baseData', parentName: '风险等级'}},
        {width: 180, title: '隐患状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '隐患状态'}},
        {width: 150, title: '整改闭环日期', key: 'rectifyTime', align: 'center', renderConfig: {type: 'formatDate'}},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            // {
            //   type: 'detail',
            //   click: () => this.modShow = true,
            //   title: '查看详情',
            //   // targetUrl: '/main/org/list/detail/' + (this.tableSelectItem.length === 1 ? this.tableSelectItem[0].id : null),
            //   icon: 'md-eye',
            //   disabled: this.tableSelectItem.length !== 1
            // },
          ]
        },
      ]
    },
  },
  mounted() {
    // if(!this.params.serveOrgId) {
    //   this.$Notice.error({
    //     title: '检索条件必须选择一个服务机构',
    //   });
    // }
    // if(!this.params.insOrgId) {
    //   this.$Notice.error({
    //     title: '检索条件必须选择一个保险机构',
    //   });
    // }
    this.getList();
  },
  methods: {
    searchFun() {
      // if(!this.params.serveOrgId) {
      //   this.serviceFlag = true
      //   this.$Notice.error({
      //     title: '检索条件必须选择一个服务机构',
      //   });
      //   return
      // }
      // if(!this.params.insOrgId) {
      //   this.insOrgFlag = true
      //   this.$Notice.error({
      //     title: '检索条件必须选择一个保险机构',
      //   });
      //   return
      // }
      this.getList()
    },
    regionChange(data) {
      this.params.provinceCode = data.length > 0 ? data[0].code : '';
      this.params.cityCode = data.length > 1 ? data[1].code : ''
      this.params.countyCode = data.length > 2 ? data[2].code : ''
    },
    getOrgData(data) {
      if(this.serviceFlag) {
        this.params.serveOrgId = data[0].id
        this.params.serviceName = data[0].iname
      }
      if(this.insOrgFlag) {
        this.params.insOrgId = data[0].id
        this.params.insOrgName = data[0].iname
      }
    }
  },
  watch: {
    'params.serviceName'() {
      if(this.params.serviceName.length <= 0) {
        this.params.serveOrgId = ''
      }
    },
    'params.insOrgName'() {
      if(this.params.insOrgName.length <= 0) {
        this.params.insOrgId = ''
      }
    },
    'params.daterange'() {
      this.params.signStartAt = this.params.daterange[0] || ''
      if(this.params.daterange[1] && this.params.daterange[1].split(' ')[1] === '00:00:00') {
         this.params.daterange[1] = this.params.daterange[1].split(' ')[0] + ' ' + '23:59:59'
      }
      this.params.signEndAt = this.params.daterange[1] || ''
    },
  }
}
</script>
