<template>
  <div>
    <ys-page-list
        ref="table"
        action="org/List"
        :params="params"
        :showContextMenu="false"
        :isEmptySelect="isEmptySelect"
        :multiColumn="false"
        :tableHeight="height-260"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword" show>
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
        <FormItem label="办公地所在区" prop="region">
          <ysRegion changeOnSelect v-model="params.region" @on-change="getRegion"></ysRegion>
        </FormItem>
        <FormItem label="组织类型" prop="oType">
          <Select v-model="params.oType" clearable placeholder="组织类型" style="width: 120px">
            <Option v-for="(item,index) in orgoTypeData" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="机构类型" prop="types">
          <Select v-model="params.types" multiple placeholder="机构类型" style="width: 220px">
            <Option v-for="(item,index) in orgTypesData" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
      </template>
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';
import components from '@/mixins/components';

export default {
  name: 'org-component',
  mixins: [pageList, components],
  props: {
    oType: {type: [Number, String], default: ''}, // oType默认选中的值
    oTypeListValue: {type: [Array], default: () => []}, // 组织类型可选list
    type: {type: [Number, String], default: ''}, // 单个，组织子类型
    types: {type: Array, default: () => []}, // 多个，组织子类型
    typesListValue: {type: [Array], default: () => []}, // 组织子类型可选list
    IDs: {type: Array, default: () => []},
    category: {type: [Number, String], default: ''},
  },
  computed: {
    orgoTypeData() {
      return this.oTypeListValue.length > 0 ? this.baseData['组织类型'].filter(item => this.oTypeListValue.some(sItem => sItem === item.value)) : this.baseData['组织类型']
    },
    orgTypesData() {
      // if(this.typesListValue.length > 0) return
      let baseData = [];
      switch (this.params.oType) {
        case 1:
          baseData = this.typesListValue.length > 0 ? this.baseData['保险公司类型'].filter(item => this.typesListValue.some(sItem => sItem === item.value)) : this.baseData['保险公司类型'];
          break;
        case 2:
          baseData = this.typesListValue.length > 0 ? this.baseData['职能部门类型'].filter(item => this.typesListValue.some(sItem => sItem === item.value)) : this.baseData['职能部门类型'];
          break;
        case 3:
          baseData = this.typesListValue.length > 0 ? this.baseData['服务机构类型'].filter(item => this.typesListValue.some(sItem => sItem === item.value)) : this.baseData['服务机构类型'];
          break;
        case 4:
          baseData = this.typesListValue.length > 0 ? this.baseData['参建单位类型'].filter(item => this.typesListValue.some(sItem => sItem === item.value)) : this.baseData['参建单位类型'];
          break;
        case 5:
          baseData = this.typesListValue.length > 0 ? this.baseData['运营中心类型'].filter(item => this.typesListValue.some(sItem => sItem === item.value)) : this.baseData['运营中心类型'];
          break;
      }
      return baseData;
    }
  },
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 80, align: 'center'},
        {minWidth: 230, title: '机构名称', key: 'iname', align: 'left',},
        {width: 100, title: '组织类型', key: 'oType', align: 'center', renderConfig: {type: 'baseData', parentName: '组织类型'}},
        {width: 100, title: '机构类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '组织子类型'}},
        {width: 130, title: '机构简称', key: 'shortName', align: 'left'},
        {width: 200, title: '社会信用代码', key: 'societyCode', align: 'left'},
        {width: 100, title: '联系人', key: 'contacts', align: 'left'},
        {width: 150, title: '联系电话', key: 'phone', align: 'left'},
        {width: 230, title: '办公地所在区', key: 'countyCode', align: 'left', renderConfig: {type: 'area', key: ['countyCode', 'cityCode', 'provinceCode']}},
        {width: 180, title: '创建时间', key: 'createdAt', align: 'left'},
        {width: 180, title: '最近修改时间', key: 'updatedAt', align: 'left'},
      ],
      params: {
        countyCode: '',
        cityCode: '',
        provinceCode: '',
        oType: '',
        region: '',
        type: '',
        types: '',
        keyword: '',
      },
    }
  },
  mounted() {
    this.params.hasAzx = this.hasAzx;
    this.params.type = this.type;
    this.params.types = this.typesListValue.length > 0 ? this.typesListValue : this.types;
    this.params.oType = this.oTypeListValue.length > 0 ? this.oTypeListValue[0] : this.oType;
    this.getList();
  },
  methods: {
    getRegion(data) {
      this.params.countyCode = data[2] ? data[2].code : '';
      this.params.cityCode = data[1] ? data[1].code : '';
      this.params.provinceCode = data[0] ? data[0].code : '';
    },
  },
  watch: {
    "params.oType"() {
      let baseData = []
      switch (this.params.oType) {
        case 1:
          baseData = this.baseData['保险公司类型'];
          break;
        case 2:
          baseData = this.baseData['职能部门类型'];
          break;
        case 3:
          baseData = this.baseData['服务机构类型'];
          break;
        case 4:
          baseData = this.baseData['参建单位类型'];
          break;
        case 5:
          baseData = this.baseData['运营中心类型'];
          break;
      }
      // 判断若所选机构类型中有不属于当前组织类型的则直接将所选机构类型清空
      let flag = this.params.types.every(item => {
        return baseData.some(ite => ite.value === item)
      })
      if(!flag) {
        this.params.types = []
      }
    }
  }
}
</script>
