export default {
  name:'components-mixins',
  props: {
    limit: {
      type: Number,
      default: 0
    },
    parent: { // 父组件，通过此可操作到父组件弹框绑定的modShow
      default: ''
    }
  },
  computed: {
    isEmptySelect() {
      return this.limit === 1;
    },
  },
  watch: {},
  data() {
    return {
      modal: '',
    }
  },
  created() {
    this.modal = this.parent || this.$parent.$parent;
  },
  methods: {
    close() {
      this.modal.modShow = false;
    },
    ok() {
      if (this.tableSelectItem.length === 0) {
        this.$Notice.warning({
          title: "操作提示！",
          desc: "请选择至少一条数据！"
        });
        return false;
      }
      if (this.limit !== 0) {
        if (this.tableSelectItem.length > this.limit) {
          this.$Notice.warning({
            title: "操作提示！",
            desc: "最多可选择 " + this.limit + ' 条数据！',
          });
          return false;
        }
      }
      this.$emit('on-ok', this.tableSelectItem);
      this.close();
    },
  }
}
